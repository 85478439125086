import FacebookPixelTracker from "./facebookPixelTracking";
import { JoinedCart } from "@/types/supabase-custom/JoinedCart";
import { JoinedLineItem } from "@/types/supabase-custom/JoinedLineItem";

class EventTrackingManager {
  public constructor() {}

  public pageViewed() {
    FacebookPixelTracker.pageViewed();
  }

  public visit() {
    FacebookPixelTracker.visit();
  }

  public addPaymentInfo(cart: JoinedCart) {
    FacebookPixelTracker.addPaymentInfo(cart);
  }

  public completeRegistration() {
    FacebookPixelTracker.completeRegistration();
  }

  public lead(cart: JoinedCart) {
    FacebookPixelTracker.lead(cart);
  }

  public purchase(totalPrice: number, currency: string) {
    FacebookPixelTracker.purchase(+totalPrice, currency);
  }

  public addToCart(lineItem: JoinedLineItem) {
    FacebookPixelTracker.addToCart(lineItem);
  }

  public initiateCheckout(cart: JoinedCart) {
    FacebookPixelTracker.initiateCheckout(cart);
  }
}

const EventTracker = new EventTrackingManager();
export default EventTracker;
