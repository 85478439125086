import React from "react";

interface CurrencyProps {
  currency?: string;
  amount?: number;
  className?: string;
}

export default function Currency({ currency, amount, className = "" }: CurrencyProps) {
  if (!amount) return <></>;
  let parsedCurrency = "";
  switch (currency) {
    case "USD":
      parsedCurrency = "$";
      break;
    case "EUR":
      parsedCurrency = "€";
      break;
    case "GBP":
      parsedCurrency = "£";
      break;
  }
  return (
    <span className={className}>{Intl.NumberFormat("en-US", { style: "currency", currency }).format(amount)}</span>
  );
}
