import Image from "next/image";
import { SupabaseUtils } from "@/lib/supabase/supabaseUtils";
import { createClient } from "@/lib/supabase/client";
import { useState } from "react";
import { useRouter } from "next/navigation";
import { filter, property } from "lodash";
import { JoinedProduct } from "@/types/supabase-custom/JoinedProduct";
import Currency from "../Currency";
import { ClickableHint } from "../ui/clickable-hint-tooltip";
import { Switch } from "../ui/switch";
import ProductSizeTable, { SizeMeasurement } from "../product-size-chart/ProductSizeTable ";
import { PriceThreshold } from "@/lib/utils/productUtils";
import Link from "next/link";
import { cn } from "@/lib/utils/stylesUtils";
import { Button, buttonVariants } from "../ui/button";
import { useStoreActions, useStoreState } from "@/hooks/storeHooks";
import { addToCart } from "@/app/studio/(public)/products/actions";
import { getCanvaData, mapCanvaDataToConfig } from "@/lib/utils/canvaUtils";
import { OrientationType } from "@/types/enums/orientationType.enum";
import { ProductColorMode } from "@/app/admin-store/(authenticated)/products/create/newProductConstants";
import { CanvaOrientations } from "@/app/studio/(public)/products/[productHandle]/components/reducers/canvaOrientationsReducer";
import useNeverthrowAsync from "@/hooks/useNeverthrowAsync";
import { TablesInsert } from "@/types";
import { v4 as uuidv4 } from "uuid";
import { err, ok } from "neverthrow";
import ProductSizeChartPopover from "./ProductSizeChartPopover";

interface Props {
  product: JoinedProduct;
  hideSelectButton?: boolean;
}

export function getSpecsFromProduct(product: JoinedProduct) {
  return filter(
    [
      { label: "Fit", value: product.fit },
      { label: "Fabric", value: product.fabric },
      { label: "Construction", value: product.construction },
      { label: "Finishing", value: product.finishing },
      { label: "Origin", value: product.origin },
    ],
    property("value")
  );
}

const supabaseUtils = new SupabaseUtils(createClient());

function ProductItem({ product, hideSelectButton = false }: Props) {
  const router = useRouter();

  const tenant = useStoreState((state) => state.tenant);
  const tenantId = tenant?.id || "";

  const specs = getSpecsFromProduct(product);

  const cart = useStoreState((state) => state.cart);
  // const configuration = useStoreState((state) => state.configuration);
  // console.log("config", configuration);
  const fetchCartThunk = useStoreActions((state) => state.fetchCartThunk);
  const createCartThunk = useStoreActions((actions) => actions.createCartThunk);
  const addCartItemThunk = useStoreActions((actions) => actions.addCartItemThunk);
  const tenantStripeAccountId = tenant?.stripe_account_id || "";

  const [handleAddBlankToCart, isAddBlankToCartLoading] = useNeverthrowAsync(
    async function handleAddToCart() {
      // 1. generate canvaData
      const canvaOrientations = {
        front: getCanvaData({
          tenantUUID: tenantId,
          orientation: OrientationType.Front,
          productUUID: product.uuid,
          colorConfig: product.colors?.[0] as any,
          colorMode: product.color_mode as ProductColorMode,
        }),
        back: getCanvaData({
          tenantUUID: tenantId,
          orientation: OrientationType.Back,
          productUUID: product.uuid,
          colorConfig: product.colors?.[0] as any,
          colorMode: product.color_mode as ProductColorMode,
        }),
        label: getCanvaData({
          tenantUUID: tenantId,
          orientation: OrientationType.Label,
          productUUID: product.uuid,
          colorConfig: product.colors?.[0] as any,
          colorMode: product.color_mode as ProductColorMode,
        }),
      };

      // 2. create a new configuration
      const config = {
        uuid: uuidv4(),
        product_id: product.id,
        tenant_id: tenantId,
        customizations: canvaOrientations as any as CanvaOrientations,
        ...mapCanvaDataToConfig(canvaOrientations, product),
      };
      // 3. submit configuration to DB
      const configResult = await supabaseUtils.createConfiguration(config as any as TablesInsert<"configurations">);
      if (configResult.isErr()) {
        return err(configResult.error);
      }
      console.log("configResult", configResult.value);
      //  4. add blank to cart and route to cart
      const addToCartResult = await addToCart({
        product,
        configuration: configResult.value,
        cart,
        createCartThunk,
        addCartItemThunk,
        fetchCartThunk,
        tenantId: tenant?.id as string,
        productModifiers: [],
        tenantStripeAccountId,
        isBlank: true,
      });

      console.log("addToCartResult", addToCartResult);
      if (addToCartResult.isOk()) {
        router.push(`/cart/${addToCartResult.value.uuid}`);
        return addToCartResult;
      } else {
        return err(addToCartResult.error);
      }
    },
    { uiSuccessMessage: "", uiErrorMessage: "Failed to add blank to cart" }
  );

  const imageURL = product.images?.length
    ? product.images[0].startsWith("http")
      ? product.images[0]
      : supabaseUtils.getImageUrl("products", `${tenant?.id}/${product.uuid}/${product.images[0]}`)
    : "";

  return (
    <div className="border rounded flex flex-col h-full">
      <div className="mb-2">
        <div className="">
          <div>
            <div className="p-0 mb-2">
              {imageURL ? (
                <div className="max-w-64 mx-auto">
                  <Link href={`/products/${product.handle}/`}>
                    <Image
                      src={imageURL}
                      alt=""
                      width={500}
                      height={500}
                      className="mx-auto aspect-square object-contain"
                    />
                  </Link>
                </div>
              ) : (
                <div>
                  <span className="text-gray-400">No image</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col flex-grow p-4">
        <div className="grow">
          <div className="text-lg font-bold text-left">{product.name}</div>
          <p className="text-left text-sm">{product.description}</p>
          {specs || (product.size_measurements && product.size_measurements.length > 0) || product.size_chart_image ? (
            <div className="mt-2 mb-4 hidden sm:block">
              {specs.length > 0 && (
                <ClickableHint label="Tech. Specs" className="text-muted-foreground">
                  <div className="p-3 space-y-2.5">
                    {specs.map(({ label, value }) => (
                      <div key={label}>
                        <p className="font-semibold text-sm">{label}</p>
                        <p className="text-sm">{value}</p>
                      </div>
                    ))}
                  </div>
                </ClickableHint>
              )}
              <ProductSizeChartPopover product={product} />
            </div>
          ) : (
            <></>
          )}
        </div>

        {tenant?.allow_blanks ? (
          <div className="flex flex-col gap-4">
            {(product.thresholds as PriceThreshold[]) &&
              (product.thresholds as PriceThreshold[]).length > 0 &&
              tenant?.show_prices_on_listings && (
                <div className="flex space-x-2">
                  <span className="font-semibold">Price starts at</span>
                  <span>
                    <Currency currency={"USD"} amount={(product.thresholds as PriceThreshold[])[0].price} />
                  </span>
                </div>
              )}
            <div className="grid grid-cols-2 gap-4">
              <Link
                type="button"
                href={`/products/${product.handle}/`}
                className={cn(buttonVariants({ variant: "default" }))}
              >
                Customize
              </Link>
              <Button
                type="button"
                variant={"outline"}
                onClick={handleAddBlankToCart}
                loading={isAddBlankToCartLoading}
              >
                Buy Blank Only
              </Button>
            </div>
          </div>
        ) : (
          <div className="flex flex-col sm:flex-row items-center w-full">
            {(product.thresholds as PriceThreshold[]) &&
              (product.thresholds as PriceThreshold[]).length > 0 &&
              tenant?.show_prices_on_listings && (
                <div className="flex sm:flex-col flex-row space-x-2 sm:space-x-0 mb-2 sm:mb-0">
                  <span className="font-semibold">Price starts at</span>
                  <span>
                    <Currency currency={"USD"} amount={(product.thresholds as PriceThreshold[])[0].price} />
                  </span>
                </div>
              )}
            <div className="grow"></div>
            {!hideSelectButton && (
              <div className="w-full sm:w-auto">
                <Link
                  type="button"
                  href={`/products/${product.handle}/`}
                  className={cn(buttonVariants({ variant: "default" }), "w-full sm:w-auto")}
                >
                  Select
                </Link>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default ProductItem;
