"use client";
import { HelpCircle } from "lucide-react";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { Button } from "./button";
import { cn } from "@/lib/utils/stylesUtils";

export interface ClickableHintTooltipProps {
  message?: string;
  title?: string;
  className?: string;
  children?: React.ReactNode;
  label?: string;
  childrenClassName?: string;
  anchor?: TooltipAnchor;
}
export interface TooltipAnchor {
  side?: "top" | "bottom" | "left" | "right";
  align?: "start" | "center" | "end";
}

export function ClickableHint({
  childrenClassName,
  message,
  title,
  label,
  className,
  children,
  anchor,
}: ClickableHintTooltipProps) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant={"link"} className={cn("p-0 m-0 leading-none inline-block h-4 ", className)}>
          <div className="flex flex-row items-center space-x-1">
            {label && <span className="text-sm">{label}</span>}
            <HelpCircle className="h-4 w-4 inline" />
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent className={cn(`w-80 mt-2 ${children ? "p-0" : ""}`, childrenClassName)} {...anchor}>
        <div className="grid gap-4">
          {children ? (
            <>{children}</>
          ) : (
            <div className="space-y-2">
              {title && <h4 className="font-medium leading-none">{title}</h4>}
              {message && <p className="text-sm text-muted-foreground">{message}</p>}
            </div>
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
}
