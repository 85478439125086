import { formatQuantity } from "format-quantity";
import React from "react";

type Props = {
  measurement: number;
  convertFrom: "cm" | "inch" | "in";
  convertTo: "cm" | "inch" | "in";
  inchMaxRounding?: number;
  roundInches?: boolean;
};

/**
 * Converts a measurement between cm and inches. CM are always displayed using decimals (e.g. 12.75), and inches are displayed using fractions rounded to the nearest fraction defined by `inchMaxRounding` (default: 1/16th). Disable rounding with `roundInches = false`.
 */
export function getMeasurementText(
  measurement: number,
  convertFrom: "cm" | "inch" | "in",
  convertTo: "cm" | "inch" | "in",
  inchMaxRounding = 16,
  roundInches = true
): string {
  if (measurement === 0) return "0";
  if (convertFrom === convertTo) return measurement.toString();

  let convertedMeasurement = measurement;

  // Conversion logic
  if (convertFrom === "cm" && (convertTo === "inch" || convertTo === "in")) {
    convertedMeasurement = measurement / 2.54;
  } else if ((convertFrom === "inch" || convertFrom === "in") && convertTo === "cm") {
    convertedMeasurement = measurement * 2.54;
  }

  if (convertTo === "cm") {
    return convertedMeasurement.toFixed(2);
  }

  if (convertTo === "inch" || convertTo === "in") {
    if (roundInches) {
      convertedMeasurement *= inchMaxRounding;
      convertedMeasurement = Math.round(convertedMeasurement);
      convertedMeasurement /= inchMaxRounding;
    }
    return "" + formatQuantity(convertedMeasurement.toFixed(2), { fractionSlash: true });
  }

  return convertedMeasurement.toString();
}

export default function Measurement({
  measurement,
  convertFrom,
  convertTo,
  inchMaxRounding = 16,
  roundInches = true,
}: Props) {
  if (measurement === 0) return <>-</>;

  return <>{getMeasurementText(measurement, convertFrom, convertTo, inchMaxRounding, roundInches)}</>;
}
