import React, { useState } from "react";
import { JoinedProduct } from "../../types/supabase-custom/JoinedProduct";
import { ClickableHint } from "../ui/clickable-hint-tooltip";
import Image from "next/image";
import { Switch } from "../ui/switch";
import ProductSizeTable, { SizeMeasurement } from "../product-size-chart/ProductSizeTable ";

export default function ProductSizeChartPopover({ product }: { product: JoinedProduct }) {
  const [selectedUnit, setSelectedUnit] = useState<"cm" | "in">(() => {
    // Try to get stored preference, default to "in" if not found
    return (localStorage.getItem("userPreferredUnit") as "cm" | "in") || "in";
  });

  const toggleMeasurementUnit = (e: any) => {
    setSelectedUnit((prev) => {
      const newUnit = prev === "cm" ? "in" : "cm";
      // Store the new preference in localStorage
      localStorage.setItem("userPreferredUnit", newUnit);
      return newUnit;
    });
  };

  if (!product.size_measurements || product.size_measurements.length === 0) {
    return <></>;
  }
  return (
    <>
      {(product.size_chart_image || (product.size_measurements && product.size_measurements.length > 0)) && (
        <ClickableHint label="Size Chart" className="ml-2 text-muted-foreground" childrenClassName="w-full">
          <div className="p-4">
            <div className="flex items-center space-x-4">
              {product.size_chart_image && (
                <div className="w-3/5 min-w-16 max-w-60">
                  <Image
                    className="w-full h-auto"
                    src={`/size-charts/${product.size_chart_image}`}
                    alt={""}
                    width={0}
                    height={0}
                    sizes="18rem"
                  />
                </div>
              )}
              {product.size_measurements && product.size_measurements.length > 0 && (
                <div className="w-full">
                  <div className="flex flex-row text-sm">
                    <div className="flex-grow"></div>
                    <div className="items-center flex flex-row space-x-2">
                      <span>Measurement in:</span>
                      <span>cm</span>
                      <Switch onClick={toggleMeasurementUnit} checked={selectedUnit === "in"} />
                      <span>in</span>
                    </div>
                  </div>
                  <div className="w-full overflow-auto max-w-[500px] lg:max-w-none">
                    <ProductSizeTable
                      sizeDimensions={product.size_measurements as SizeMeasurement[]}
                      measurementUnit={product.measurement_unit}
                      selectedUnit={selectedUnit}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </ClickableHint>
      )}
    </>
  );
}
