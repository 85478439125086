import FacebookPixelEventsEnum from "@/types/enums/facebookPixelEvents.enum";
import getCookie from "src/helpers/cookie/getCookie";
import getLocalStorage from "src/helpers/local-storage/getLocalStorage";
import { JoinedCart } from "@/types/supabase-custom/JoinedCart";
// import { captureException } from "@sentry/nextjs";
import sleep from "src/helpers/sleep";
// import highlightError from "../highlight/highlightError";
import { JoinedLineItem } from "@/types/supabase-custom/JoinedLineItem";

class FacebookPixelTracking {
  public constructor() {}

  protected facebookPixelTrack(name: string, data: object = {}, retries = 5) {
   
    const facebookPixelId = getLocalStorage("fb_pixel_id");
    
    const advancedMatching: any = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    };
    import("react-facebook-pixel")
      .then((x) => x.default)
      .then((ReactPixel) => {
        // ReactPixel.init('3249689655287552', advancedMatching, options); 1
        // ReactPixel.init('1189600331630885', advancedMatching, options);  2
        // ReactPixel.init('662737591889517', advancedMatching, options); 3

        ReactPixel.init(facebookPixelId, advancedMatching, options);
        ReactPixel.track(name, data);
        // console.log("facebookPixelId", facebookPixelId);
        console.log("fbpEv",name, data);
      })
      .catch(async (error) => {
        if (retries > 0) {
          console.count("React pixel retry");
          await sleep(3000);
          this.facebookPixelTrack(name, data, retries - 1);
        } else {
          // highlightError(error, error.message);
          // captureException(error.message);
        }
      });
  }

  protected checkoutDetails(cart: JoinedCart) {
    let cartItemNames: string[] = [];
    cart.line_items.map((item) => {
      cartItemNames.push(item.configurations.products.handle || "");
    });
    let cartItemIDs: number[] = [];
    cart.line_items.map((item) => {
      cartItemIDs.push(item.configurations.products.id);
    });
    return {
      content_ids: cartItemIDs,
      value: cart.total_price,
      currency: cart.currency,
      num_items: cart.line_items.length,
      // contents: cartItemNames,
    };
  }

  protected purchaseDetails(totalPrice: number, currency: string) {
    return {
      value: totalPrice,
      currency: currency,
    };
  }

  protected pageViewDetails() {
    return {
      Environment: process.env.NEXT_PUBLIC_ENV,
    };
  }
  
  protected productDetails(lineItem: JoinedLineItem) {
    return {
      content_name: lineItem.configurations.products.handle,
      currency: "USD",
      value: lineItem.price,
      content_ids: [lineItem.id],
      content_type: "product",
    };
  }
  public visit() {
    this.facebookPixelTrack(FacebookPixelEventsEnum.VISIT, this.pageViewDetails());
  }

  public pageViewed() {
    this.facebookPixelTrack(FacebookPixelEventsEnum.PAGE_VIEW, this.pageViewDetails());
  }

  public addToCart(lineItem: JoinedLineItem) {
    this.facebookPixelTrack(FacebookPixelEventsEnum.ADD_TO_CART, this.productDetails(lineItem));
  }

  public initiateCheckout(cart: JoinedCart) {
    this.facebookPixelTrack(FacebookPixelEventsEnum.INITIATE_CHECKOUT, this.checkoutDetails(cart));
  }

  public completeRegistration() {
    this.facebookPixelTrack(FacebookPixelEventsEnum.COMPLETE_REGISTRATION);
  }

  // public contact() {
  //   this.facebookPixelTrack(FacebookPixelEventsEnum.CONTACT);
  // }



  public lead(cart: JoinedCart) {
    this.facebookPixelTrack(FacebookPixelEventsEnum.LEAD, this.checkoutDetails(cart));
  }

  public addPaymentInfo(cart: JoinedCart) {
    this.facebookPixelTrack(FacebookPixelEventsEnum.ADD_PAYMENT_INFO, this.checkoutDetails(cart));
  }

  public purchase(totalPrice: number, currency: string) {
    if (getCookie("_fbc") || getCookie("fbc") || getCookie("fbclid")) {
      this.facebookPixelTrack(FacebookPixelEventsEnum.PURCHASE, this.purchaseDetails(totalPrice, currency));
      console.log(
        "event and paramenters from Facebookpixel tracking inside",
        FacebookPixelEventsEnum.PURCHASE,
        totalPrice,
        currency
      );
    }
    console.log(
      "event and paramenters from Facebookpixel tracking outside",
      FacebookPixelEventsEnum.PURCHASE,
      totalPrice,
      currency
    );
  }
}

const FacebookPixelTracker = new FacebookPixelTracking();
export default FacebookPixelTracker;
