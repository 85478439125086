import { flow, negate } from "lodash";
import fp from "lodash/fp";
import Measurement from "./Measurement";
import { orderSizes } from "@/lib/utils/sizeUtils";
import { Table, TableRow, TableCell, TableHeader, TableBody } from "@/components/ui/table";

type Props = {
  sizeDimensions: SizeMeasurement[];
  measurementUnit: "cm" | "in";
  selectedUnit: "cm" | "in";
};

export interface SizeMeasurement {
  measurement: string;
  [key: string]: string | number;
}

const ProductSizeTable = ({ sizeDimensions, selectedUnit, measurementUnit }: Props) => {
  const sizes: string[] = flow(fp.keys, fp.filter(negate(fp.eq("measurement"))))(sizeDimensions[0]);

  const orderedSizes = orderSizes(sizes);

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableCell>Measurement</TableCell>
          {orderedSizes.map((size) => (
            <TableCell key={size}>{size.toUpperCase()}</TableCell>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {sizeDimensions.map((row, rowIndex) => (
          <TableRow key={rowIndex}>
            <TableCell>{row.measurement}</TableCell>
            {orderedSizes.map((size) => (
              <TableCell key={size}>
                <span className="whitespace-nowrap">
                  <Measurement
                    measurement={row[size] as number}
                    convertFrom={measurementUnit}
                    convertTo={selectedUnit}
                  />
                </span>
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default ProductSizeTable;
