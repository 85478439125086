import { Size } from "@/types/supabase-custom/Customization";
import { values } from "lodash";

export const lowerCaseSizeOrder = values(Size).map((size) => size.toLowerCase()) as string[];

export function orderSizes(sizes: string[]) {
  const lowerCaseSizes = sizes.map((size) => size.toLowerCase()) as string[];
  return lowerCaseSizes.sort((a, b) => {
    return lowerCaseSizeOrder.indexOf(a) - lowerCaseSizeOrder.indexOf(b);
  });
}
